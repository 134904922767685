import { Rolle } from "./rolle";

export class Benutzer {
  id: number;
  cadsId: number;
  vorname: string;
  nachname: string;
  email: string;
  benutzerRollen: Rolle[]
  loggedIn: boolean;

  constructor(obj?: any) {
    this.id = 0 ;
    this.cadsId = 0;
    this.vorname = ''
    this.nachname = 'Unangemeldet';
    this.email = '';
    this.benutzerRollen = [] as Rolle[];
    this.loggedIn = false;

    if(obj){
      for(let key in obj){
        if(key in this){
          // @ts-ignore
          this[key] = obj[key];
        }
      }
    }
  }
}
