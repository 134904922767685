import {Component, Injectable, Input, EventEmitter, OnInit, Output} from '@angular/core';
import {BenutzerService} from "../../services/benutzer.service";
import {Result} from "../../entity/result";
import {Router} from "@angular/router";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

@Injectable({
  providedIn: "root"
})
export class LoginComponent implements OnInit {

  @Input() result!: Result;
  @Input() zeige_abbrechen_button: boolean;
  @Output() result_change = new EventEmitter<Result>();

  /**
   * Credentails
   * */
  benutzername: string;
  passwort: string;

  /**
   * Errorvariablen
   * */
  error: boolean;
  errorString: string;

  /**
   * Helpervariablen
   * */
  loading: boolean;

  constructor(private benutzerService: BenutzerService, private router: Router) {
    /**
     * Credentails
     * */
    this.benutzername = '';
    this.passwort = '';

    /**
     * Errorvariablen
     * */
    this.error = false;
    this.errorString = '';

    /**
     * Helpervariablen
     * */
    this.loading = false;
    this.zeige_abbrechen_button = false;
  }

  ngOnInit(): void {
  }

  /**
   * Schließt das Dialogfenster
   * */
  onNoClick() {
    let returnObjekt = new Result();
    returnObjekt.success = true;
    this.result_change.emit(returnObjekt);
  }

  /**
   * Loginabfrage gegen Cads Datenbank mit Credentails
   * */
  login(): void {
    if (this.benutzername && this.passwort) {
      this.loading = true;
      this.benutzerService.login(this.benutzername, this.passwort).subscribe(
        result => {
          if (result.success) {
           // this.router.navigate(['/dev']);

            this.router.navigate(['/']);
            this.result = result;
            this.result_change.emit(this.result);
          } else {
            this.error = true;
            this.errorString = result.error || '';
          }
          this.loading = false;
        }
      );
    }
  }
}
