import {Component} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Inject} from "@angular/core";

@Component({
  selector: 'dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  return: any

  constructor(
    public dialog_ref: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  /**
   * Schließt das Dialogfenster wenn Event erfolgreich
   * @param $event: Responseobjekt
   * */
  close($event: any): void {
    if ($event.success) {
      this.dialog_ref.close($event);
    }
  }

  /**
   * Schließt das Dialogfenster
   * */
  onNoClick(): void {
    this.dialog_ref.close();
  }
}
