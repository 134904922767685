import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Result} from "../entity/result";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";

const adress = '';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient, private router: Router) {
  }

  /**
   * POST an SYMFONY Backend
   * @param body: Postbody
   * @param adressEnd: Endpunkt
   * @return Observable Result
   * */
  post(body: object | string, adressEnd: string): Observable<Result> {
    let bodyStr = this.bodyConvert(body);
    return this.http.post<any>
    (adress + adressEnd, bodyStr).pipe(map(resp => {
      const result = resp as Result;
      if (result) {
        if (result.error403) {
          this.router.navigate(['/403', {}]);

        } else if (result.error404) {
          this.router.navigate(['/404', {}]);
        }
        return result;
      } else {
        return {success: false, error: 'Allgemeiner Serverfehler'}
      }
    }));
  }

  private bodyConvert(abc: any): string {
    let bodyStr = '';
    let k: keyof any;
    for (k in abc) {
      bodyStr += k + '=' + abc[k] + '&';
    }
    bodyStr = bodyStr.slice(0, -1);
    return bodyStr;
  }
}
