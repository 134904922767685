import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() height = 20;
  @Input() width = 120;
  @Input() marginTop = 40;
  @Input() marginBottom = 40;
  @HostBinding('style.animationDuration')
  @Input() animationDuration = '0.8s';
  /**
   * Helpervariablen
   * */
  show_snail = false;
  show_tortoise = false;

  constructor() {
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //     this.show_snail = true;
    //     this.height += 20;
    //   },
    //   2000
    // );
    // setTimeout(() => {
    //     this.show_tortoise = true;
    //   },
    //   4000
    // );
  }
}
