<div class="main-container">
<h1>Login</h1>

<div>
  <div class="">
    <div>
      <mat-form-field>
        <mat-label>Benutzername</mat-label>
        <input #benutzer="ngModel" type="text" matInput [(ngModel)]="benutzername" (keydown.enter)="login()" required>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Passwort</mat-label>
        <input #pw="ngModel" type="password" matInput [(ngModel)]="passwort" (keydown.enter)="login()" required>
      </mat-form-field>
    </div>
  </div>

  <div>
    <span *ngIf="zeige_abbrechen_button">
      <button mat-raised-button (click)="onNoClick()" style="margin-right: 1em;" >Abbrechen</button>
    </span>
    <span>
      <button class="anmelden" mat-raised-button (click)="login()" >Anmelden</button>
    </span>
  </div>

  <loading *ngIf="loading"></loading>

  <div *ngIf="error" style="margin-top: 1em; margin-bottom: 1em; color:red;">
    <label>Fehler bei der Anmeldung:</label>
    <div>{{errorString}}</div>
  </div>

</div>
</div>
