import {Injectable} from '@angular/core';
import {Benutzer} from "../entity/benutzer";
import {ApiService} from "./api.service";
import {Result} from "../entity/result";
import {Observable, of, Subject} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Router, UrlTree} from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class BenutzerService {

  private benutzer = new Subject<Benutzer>();
  private unit: number;

  constructor(private api: ApiService, private router: Router) {
    this.unit = 0;
  }

  /**
   * Liefert aktuelles Benutzersubject
   * @return Subject Benutzer
   * */
  getBenutzer(): Subject<Benutzer> {
    return this.benutzer;
  }

  /**
   * Setzt BenutzerSubject auf Aktuellen Benutzer
   * @param benutzer: Benutzerobjekt
   * */
  setBenutzer(benutzer: Benutzer): void {
    this.benutzer.next(benutzer);
  }

  /**
   * Prüft, ob Benutzer eingeloggt ist
   * @return Observable boolean oder UrlTree
   * */
  checkLogin(): Observable<boolean | UrlTree> | boolean {

    return this.api.post({}, '/api/checklogin').pipe(map(result => {

      if (result.success) {
        const content = JSON.parse(result.content);
        // const benutzer = result.content as Benutzer;
        const benutzer = content as Benutzer;
        benutzer.loggedIn = true;
        this.setBenutzer(benutzer);
        return true;
      } else {
        this.setBenutzer(new Benutzer());
      //  return this.router.parseUrl('/dev/index');
        return this.router.parseUrl('/login');
      }
    }, catchError((err, caught) =>
    //  of(this.router.parseUrl('/dev/index'))
    of(this.router.parseUrl('/login'))
    )));
  }

  /**
   * Logoutfunktion mit Abfrage gehen Symfonybackend und Weiterleitung zur Indexseite
   * */
  logout(): void {
    this.api.post({}, '/api/logout').subscribe();
    this.router.navigate(['/login']);
    this.benutzer.next(new Benutzer());
    //this.router.navigate(['/dev', {}])
  }

  /**
   * Loginfunktion mit Abfrage gehen CADS Datenbank
   * @return Observable Result
   * */
  login(benutzername: string, passwort: string): Observable<Result> {
    const returnResult = new Result();
    const cred = {
      username: benutzername,
      password: passwort,
    };
    return this.api.post(cred, 'api/login').pipe(map(result => {
      if (result.success) {
        const benutzer = JSON.parse(result.content) as Benutzer;
        benutzer.loggedIn = true;
        this.setBenutzer(benutzer);
        returnResult.success = true;
        returnResult.content = benutzer;
        return returnResult
      } else {
        returnResult.success = false;
        returnResult.error = result.error;
        return returnResult;
      }
    }, catchError((err, caught) => {
      returnResult.success = false;
      returnResult.error = err;
      return of(returnResult);
    })));
  }

  setUnit(unit: number): void {
    this.unit = unit;
  }

  getUnit(): number {
    return this.unit;
  }
}

