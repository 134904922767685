import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'seite-nicht-gefunden',
  templateUrl: './seite-nicht-gefunden.component.html',
  styleUrls: ['./seite-nicht-gefunden.component.scss']
})
export class SeiteNichtGefundenComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
