import {Routes} from "@angular/router";
import {SeiteNichtGefundenComponent} from "./shared/view/seite-nicht-gefunden/seite-nicht-gefunden.component";
import {UserGuard} from "./shared/services/user.guard";
import {StartseiteComponent} from "./startseite/startseite.component";
import {LoginComponent} from "./shared/view/login/login.component";
import {MediBuddyUebersichtComponent} from "./medi-buddy-uebersicht/medi-buddy-uebersicht.component";

export const APP_ROUTES: Routes = [
  {
    //path: 'dev',
    path: '',
    component: StartseiteComponent,
    canActivate: [UserGuard]
  },
  {
    //path: 'dev/index',
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'uebersicht',
    component: MediBuddyUebersichtComponent,
    canActivate: [UserGuard]
  },
  {
    path: '**',
    component: SeiteNichtGefundenComponent,
    canActivate: [UserGuard]
  },
];
