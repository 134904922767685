import { Component } from '@angular/core';
import {Benutzer} from "./shared/entity/benutzer";
import {BenutzerService} from "./shared/services/benutzer.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "./shared/view/dialog/dialog.component";
import { Router, NavigationEnd   } from '@angular/router';

import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /**
   * Benutzervariable
   * */
  benutzer: Benutzer

  /**
   * Redirectvariable
   * */
  activeSeite: string;

  constructor(private benutzerService: BenutzerService, public dialog: MatDialog, private router: Router) {
    /**
     * Benutzervariable
     * */
    this.benutzer = new Benutzer();
    this.benutzerService.checkLogin();
    this.benutzerService.getBenutzer().subscribe( benutzer =>
      this.benutzer = benutzer
    );

    /**
     * Redirectvariable
     * */
    this.activeSeite = '/';
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event  =>
    {
      // @ts-ignore
      this.activeSeite ='/' + event['url'].split('/')[1];
    });
  }

  /**
   * Überprüft ob benutzer eingeloggt ist
   * @return boolean Ja / Nein Benutzer eingeloggt
   * */
  isLoggedIn(): boolean {
    return this.benutzer.loggedIn!;
  }

  /**
   * Liefert aktuellen Benuternamen
   * @return string Benutzernamen
   * */
  getUsername(): string {
    return this.benutzer.vorname! + ' ' + this.benutzer.nachname!;
  }

  /**
   * Meldet den aktuellen Benuter ab
   * */
  abmelden(): void {

    this.benutzerService.logout();
  }

  /**
   * Öffnet Logindialogfenster
   * */
  logInScreen(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: 'LoginDialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.success && result.content) {
        this.benutzer = result.content;
      }
    });
  }
}
