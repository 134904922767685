export class Result {
  success: boolean;
  content?: any;
  error?: any;
  error404?: string
  error403?: string

  constructor() {
    this.error = '';
    this.success = false;
  }
}
