import {LOCALE_ID, NgModule} from '@angular/core';
import {APP_ROUTES} from "./app.routes";
import {BrowserModule} from '@angular/platform-browser';
import {MatGridListModule} from '@angular/material/grid-list';
import {AppComponent} from './app.component';
import {PreloadAllModules, RouterModule} from "@angular/router";
import {SeiteNichtGefundenComponent} from "./shared/view/seite-nicht-gefunden/seite-nicht-gefunden.component";
import {StartseiteComponent} from './startseite/startseite.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "./shared/shared.module";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatRadioModule} from "@angular/material/radio";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSelectModule} from "@angular/material/select";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatTabsModule} from "@angular/material/tabs";
import {MatChipsModule} from "@angular/material/chips";
import {MatTableModule} from "@angular/material/table";
import {MediBuddyUebersichtComponent} from './medi-buddy-uebersicht/medi-buddy-uebersicht.component';
import {ApiInterceptor} from "./shared/helpers/api.interceptor";


registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    SeiteNichtGefundenComponent,
    StartseiteComponent,
    MediBuddyUebersichtComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(APP_ROUTES, {
      preloadingStrategy: PreloadAllModules
    }),
    MatGridListModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatChipsModule,
    MatTableModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "de" },
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: 'de'},
    {provide: APP_BASE_HREF, useValue: ''},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
  ],

  bootstrap: [AppComponent]
})

export class AppModule { }
