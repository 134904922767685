import { Component } from '@angular/core';
import {BenutzerService} from "../shared/services/benutzer.service";
import {Benutzer} from "../shared/entity/benutzer";
import {Router} from "@angular/router";


@Component({
  selector: 'startseite',
  templateUrl: './startseite.component.html',
  styleUrls: ['./startseite.component.scss']
})
export class StartseiteComponent {

  /**
   * Benutzervariablen
   * */
  aktuellerBenutzer: Benutzer;

  /**
   * Helpervariablen
   * */
  loading: boolean;

  constructor(private benutzerService: BenutzerService, private router: Router) {

    /**
     * Benutzervariablen
     * */
    this.aktuellerBenutzer = new Benutzer();

    /**
     * Helpervariablen
     * */
    this.loading = false;
  }

  ngOnInit(): void {
    this.loading = true;
    this.benutzerService.getBenutzer().subscribe(benutzer => {
      console.log(benutzer)
      this.aktuellerBenutzer = benutzer;
      this.loading = false;
    });
  }
}
