<header class="fixed-top">
  <mat-grid-list cols="2" rowHeight="5em">
    <mat-grid-tile>
      <div class="title-inside-grid">

        <div class="app-title">
          <h1><a routerLink="/">
            Zeus</a></h1>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="logo-inside-grid">
        <div class="app-logo">
          <a class="image" href="https://www.medizin.uni-muenster.de">
            <img alt="Logo Medizinische Fakultät WWU Münster" class="medfaklogo" src="assets/img/medfakneu.png"
                 title="Medizinische Fakulät der Westfälischen Wilhelms-Universität Münster">
          </a>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <nav>
    <mat-grid-list cols="1" rowHeight="3em" style="margin-top: .5em">
      <mat-grid-tile>
        <div class="auth-inside-grid">
          <div class="app-auth">
            <div *ngIf="isLoggedIn()">
              <button mat-button [matMenuTriggerFor]="menu">
                Angemeldet als {{getUsername()}}</button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="abmelden()">Abmelden</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </nav>
</header>

<div class="main-area">
  <router-outlet></router-outlet>
</div>
